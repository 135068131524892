import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';
import { useAuthContext } from 'src/auth/hooks';
import { NavListProps } from 'src/components/nav-section';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  permissions: icon('ic_permission'),
  collateral: icon('ic_collateral'),
  user: icon('ic_user_1'),
  report: icon('ic_report_1'),
  customer: icon('ic_customer'),
  branch: icon('ic_branch_1'),
  pawn: icon('ic_pawn_1'),
  operation: icon('ic_operation_1'),
};

// ----------------------------------------------------------------------

interface NavData {
  subheader: string;
  items: NavListProps[];
}

function NavChildrenFilter(items: NavListProps, permissions: string[]) {
  const children: NavListProps[] = items?.children || [];
  const hasPermission =
    children.length === 0 &&
    (!items?.permissions || items?.permissions?.filter((f) => permissions.includes(f)).length > 0);

  if (children.length > 0) {
    const newChildren = children
      .map((m) => NavChildrenFilter(m, permissions))
      .filter((f) => !!f) as any[];
    return newChildren.length > 0 ? { ...items, children: newChildren || undefined } : null;
  }

  return hasPermission ? { ...items, children: undefined } : null;
}

function NavItemFilter(nav: NavData, permissions: string[]) {
  const items = (nav.items || []).map((m) => NavChildrenFilter(m, permissions)).filter((f) => !!f);
  return items.length > 0 ? { ...nav, items: items } : null;
}

export function useNavData() {
  const { t } = useLocales();

  const { role, permissions, user } = useAuthContext();

  const data = useMemo(
    () =>
      [
        // OVERVIEW
        // ----------------------------------------------------------------------
        {
          subheader: t('overview'),
          items: [{ title: t('dashboard'), path: paths.dashboard.root, icon: ICONS.dashboard }],
        },

        // CUSTOMER
        // ----------------------------------------------------------------------
        {
          subheader: t('management'),
          items: [
            // Customer
            {
              title: t('customer'),
              path: paths.dashboard.customer.root,
              icon: ICONS.customer,
              children: [
                {
                  title: t('create'),
                  path: paths.dashboard.customer.create,
                  permissions: ['create_customer'],
                },
                {
                  title: t('list'),
                  path: paths.dashboard.customer.root,
                  permissions: ['list_customer'],
                },
              ],
            },
            // Pawn
            {
              title: t('pawn'),
              path: paths.dashboard.pawn.root,
              icon: ICONS.pawn,
              children: [
                {
                  title: t('create_fast_pawn'),
                  path: paths.dashboard.pawn.fastCreate,
                  permissions: ['create_pawn'],
                },
                {
                  title: t('create_general_pawn'),
                  path: paths.dashboard.pawn.create,
                  permissions: ['create_pawn'],
                },
                {
                  title: t('fast_pawn_list'),
                  path: paths.dashboard.pawn.smallPawn,
                  permissions: ['create_pawn'],
                },
                {
                  title: t('general_pawn_list'),
                  path: paths.dashboard.pawn.root,
                  permissions: ['create_pawn'],
                },
              ],
            },
            // Branch
            {
              title: t('branch'),
              icon: ICONS.branch,
              path: paths.dashboard.branch.root,
              children: [
                {
                  title: t('create'),
                  path: paths.dashboard.branch.create,
                  permissions: ['create_branch'],
                },
                {
                  title: t('list'),
                  path: paths.dashboard.branch.root,
                  permissions: ['list_branch'],
                },
              ],
            },
            // User
            {
              title: t('staff'),
              icon: ICONS.user,
              path: paths.dashboard.user.root,
              children: [
                {
                  title: t('create'),
                  path: paths.dashboard.user.create,
                  permissions: ['create_staff'],
                },
                { title: t('list'), path: paths.dashboard.user.root, permissions: ['list_staff'] },
                // {
                //   title: t('permission'),
                //   path: paths.dashboard.user.permission,
                //   permissions: ['permission_staff'],
                // },
              ],
            },
            // Report
            {
              title: t('report'),
              icon: ICONS.report,
              path: paths.dashboard.report.root,

              children: [
                {
                  title: t('your_report'),
                  path: paths.dashboard.report.yourReport,
                  // permissions: ['staff_report'],
                },

                {
                  title: t('staff_report'),
                  path: paths.dashboard.report.user,
                  permissions: ['staff_report'],
                },

                {
                  title: t('branch_report'),
                  path: paths.dashboard.report.branch,
                  permissions: ['branch_report'],
                },
                {
                  title: t('repayment_report'),
                  path: paths.dashboard.report.repayment,
                  permissions: ['pawn_report'],
                },
              ],
            },
            // Operation
            {
              title: t('operation'),
              icon: ICONS.operation,
              path: paths.dashboard.operation.root,
              children: [
                {
                  title: t('expend'),
                  path: paths.dashboard.operation.expend,
                  permissions: ['expend_operation'],
                },
                {
                  title: t('transfer'),
                  path: paths.dashboard.operation.transfer,
                  permissions: ['transfer_operation'],
                },
                {
                  title: t('topup'),
                  path: paths.dashboard.operation.topup,
                  permissions: ['topup_operation'],
                },
                // {
                //   title: t('deposit'),
                //   path: paths.dashboard.operation.deposit,
                //   permissions: ['deposit_staff_operation'],
                // },
                // {
                //   title: t('withdraw'),
                //   path: paths.dashboard.operation.withdraw,
                //   permissions: ['withdraw_staff_operation'],
                // },
              ],
            },
            // Operation
            {
              title: t('collateral_type'),
              icon: ICONS.collateral,
              path: paths.dashboard.collateral.root,
              children: [
                {
                  title: t('create'),
                  path: paths.dashboard.collateral.root,
                  permissions: ['create_type_pawn'],
                },
                {
                  title: t('list'),
                  path: paths.dashboard.collateral.list,
                  permissions: ['create_type_pawn'],
                },
              ],
            },
          ],
        },
      ]
        .map((m) => NavItemFilter(m, permissions))
        .filter((f) => {
          return !!f;
        }) as NavData[],
    [t, permissions]
  );

  return data;
}
