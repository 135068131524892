import { SWROptions, useMutateSWR, useQuerySWR } from 'src/utils/swr-instanct';
import endpoints from './endpoints';
import { Types } from 'src/generated/types';

export function useQueryRepaymentCount(
  options?: SWROptions<Types.Report.RepaymentCountItem, undefined>
) {
  const res = useQuerySWR(endpoints.report.repaymentCount, undefined, options);
  return res;
}

export interface RepaymentReportParams {
  limit?: string | null;
  page?: string | null;
  role?: string | null;
  start_at?: string | null;
  end_at?: string | null;
  branch_id?: string | null;
  pawn_type?: string | null;
  user_id?: string | null;
  with_customer?: number;
  with_created_by?: number;
  with_branch?: number;
  with_receipt?: number;
  with_pawn?: number;
  with_credit?: number;
}

export function useQueryRepaymentReports(
  params: RepaymentReportParams,
  options?: SWROptions<Types.Report.RepaymentReportList, RepaymentReportParams>
) {
  const res = useQuerySWR(endpoints.report.repayment, params, options);
  return res;
}

export function useQueryRepaymentTotalReports(
  params: RepaymentReportParams,
  options?: SWROptions<Types.Report.RepaymentTotal, RepaymentReportParams>
) {
  const res = useQuerySWR(endpoints.report.repaymentTotal, params, options);
  return res;
}

export interface ReportListParams {
  limit?: string | null;
  page?: string | null;
  role?: string | null;
  start_at?: string | null;
  end_at?: string | null;
  branch_id?: string | null;
  user_id?: string | null;
  with_customer?: number;
  with_created_by?: number;
  with_branch?: number;
  with_receipt?: number;
  with_credit?: number;
}

export function useQueryUserReports(
  params: ReportListParams,
  options?: SWROptions<Types.Report.UserReportList, ReportListParams>
) {
  const res = useQuerySWR(endpoints.report.user, params, options);
  return res;
}

export function useQueryUserTotalReports(
  params: ReportListParams,
  options?: SWROptions<Types.Report.UserReport, ReportListParams>
) {
  const res = useQuerySWR(endpoints.report.userTotal, params, options);
  return res;
}

export interface BranchReportParams {
  limit?: string | null;
  page?: string | null;
  start_at?: string | null;
  end_at?: string | null;
  with_credit?: number;
}

export function useQueryBranchReports(
  params: BranchReportParams,
  options?: SWROptions<Types.Report.BranchReportList, BranchReportParams>
) {
  const res = useQuerySWR(endpoints.report.branch, params, options);
  return res;
}

export function useQueryBranchTotalReports(
  params: BranchReportParams,
  options?: SWROptions<Types.Report.BranchReport, BranchReportParams>
) {
  const res = useQuerySWR(endpoints.report.branchTotal, params, options);
  return res;
}
