// ----------------------------------------------------------------------
import {
  Card,
  Grid,
  Container,
  CardHeader,
  IconButton,
  CardContent,
  InputAdornment,
} from '@mui/material';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import { useLocales } from 'src/locales';
import endpoints from 'src/api/endpoints';
import { useForm } from 'react-hook-form';
import Iconify from 'src/components/iconify';
import { Upload } from 'src/components/upload';
import { useBoolean } from 'src/hooks/use-boolean';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutateExpend } from 'src/api/operation';
import axiosInstance from 'src/utils/axios-instanct';
import { formatUrl } from 'src/utils/format-img-url';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'src/components/snackbar';
import { useSettingsContext } from 'src/components/settings';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import PermissionGuard from 'src/components/permission/role-guard';
import { useAuthContext } from 'src/auth/hooks';

export default function () {
  const { t } = useLocales();
  const password = useBoolean();
  const { refetchtMe } = useAuthContext();
  const settings = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();
  const domain = process.env?.REACT_APP_HOST_API;
  const [isLoading, setIsLoading] = useState(false);
  const token = document.cookie.split('accessToken=')[1].split('; XSRF-')[0];
  const [file, setFile] = useState<File | string | null>(null);

  const NewUserSchema = Yup.object().shape({
    amount: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    receipt_url: Yup.string(),
    password: Yup.string().required('Password is required'),
  });

  const { isLoading: isLoadingExpend, mutate: onUserExpend } = useMutateExpend(undefined, {
    onSuccess: ({ data, message }) => {
      reset();
      refetchtMe();
      setFile(null);
      enqueueSnackbar(message);
    },
    onError: (er) => {
      enqueueSnackbar(er.message, { variant: 'error' });
    },
  });

  const defaultValues = useMemo(
    () => ({
      amount: '',
      description: '',
      receipt_url: '',
      password: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (_params) => {
    const variable = {
      amount: Number(_params?.amount),
      description: _params?.description,
      receipt_url: _params?.receipt_url,
      password: _params?.password,
    };
    onUserExpend(variable);
  });

  const handleDropSingleFile = useCallback(
    (acceptedFiles: File[]) => {
      const newFile = acceptedFiles[0];
      if (newFile) {
        setFile(
          Object.assign(newFile, {
            preview: URL.createObjectURL(newFile),
          })
        );
      }
    },
    [setValue]
  );

  const onUploadCustomerImage = async () => {
    const formData = new FormData();
    formData.append('image', file as any);
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(`${domain}${endpoints.customer.upload}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      setValue('receipt_url', response?.data?.data?.image_url || '');
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (file) {
      onUploadCustomerImage();
    }
  }, [file]);

  const imageUrl = watch('receipt_url');
  return (
    <PermissionGuard hasContent permission={'expend_operation'} sx={{ py: 10 }}>
      <Container maxWidth={settings.themeStretch ? false : 'md'} sx={{ mt: 5 }}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Grid gridColumn={12}>
            <Card sx={{ p: 3 }}>
              <CardHeader title={t('your_expend')} />
              <CardContent>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(1, 1fr)',
                  }}
                >
                  <RHFTextField
                    type="number"
                    size="medium"
                    name="amount"
                    label={t(`${'amount'}`)}
                  />
                  <RHFTextField
                    rows={4}
                    multiline
                    size="medium"
                    name="description"
                    label={t(`${'description'}`)}
                  />

                  <RHFTextField
                    name="password"
                    label={t('password')}
                    type={password.value ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={password.onToggle} edge="end">
                            <Iconify
                              icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box
                    rowGap={3}
                    columnGap={2}
                    display="grid"
                    gridTemplateColumns={{
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(1, 1fr)',
                    }}
                  >
                    <Upload
                      thumbnail
                      loading={isLoading}
                      file={formatUrl(imageUrl || '')}
                      accept={{ 'image/*': [] }}
                      maxSize={3145728}
                      onDrop={handleDropSingleFile}
                      onDelete={() => {
                        setFile(null);
                        setValue('receipt_url', '');
                      }}
                    />
                  </Box>
                </Box>
                <Stack
                  spacing={1.5}
                  mt={4}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <LoadingButton
                    fullWidth
                    type="submit"
                    size="large"
                    color="primary"
                    variant="contained"
                    loading={isSubmitting || isLoadingExpend}
                  >
                    {t('expend')}
                  </LoadingButton>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </FormProvider>
      </Container>
    </PermissionGuard>
  );
}
